/*
XR-Mobility Showcase
*/

import * as React from "react"
import {graphql} from "gatsby";
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import {useTranslation, useI18next} from 'gatsby-plugin-react-i18next';

//Components
import Layout from "../../components/layout";
import { abschnitt as Abschnitt, trenner as Trenner } from "../../components/layoutComponents";
import Header from "../../components/header/header";
import HR from "./../../components/generics/HR";
import ProjectHeader from "../../components/projects/ProjectHeader"
import ContentBlock1S from "../../components/projects/ContentBlock1S";
import FlyingContactClick from "../../components/contactFlyingButton_click.js";
import SEO from './../../components/generics/seo';
import ProjectFooter from "../../components/projects/ProjectFooter";
import Reihe from "../../components/generics/reihe";
import SpalteLinks from "../../components/generics/spalte_links";
import SpalteRechts from "../../components/generics/spalte_rechts";

//CSS
import "../../components/contactFlyingButton.css";
import "../../components/teaser/teaser.css"

// Videos
import {
    mob_teaser_640, mob_communication_640, mob_exterior_640,
    mob_apps_640, mob_games_640, mob_interface_640, mob_interior_640
} from '../../videos/projects/mobility';
import {
    mob_teaser_1920, mob_communication_1920, mob_exterior_1920,
    mob_apps_1920, mob_games_1920, mob_interface_1920, mob_interior_1920
} from '../../videos/projects/mobility';

//Poster
import poster from '../../videos/projects/mobility/mob_teaser_poster.jpg';

const AMobility = (location) => {
    const breakpoints = useBreakpoint();
    const { t } = useTranslation('XR-Mobility');
    const { language } = useI18next();

    return (
        <>
              {/* Schreibt SEO Optimierungen für die Seite (Titel, Canonical-Link, Beschreibung */}
              {/* LD/JSON bei Bedarf: Product oder ContactPoint von schema.org */}
              <SEO
                  lang={language}
                  title={t('meta title')}
                  canonical={t('meta canonical')}
                  description={t('meta description')}
                  productLDJSON={true} />

              <Layout>
                  <Header location={location} />
                  {breakpoints.md ? <></> :  <Trenner /> }

                  <Abschnitt>
                      {/* Direkt die übersetzten Texte nutzen */}
                      {/* Für HTML Inhalte des Langtextes html={} nutzen, ohne Formatierungen das text={} Attribut */}
                      {/* Für ein Video muss videoHeader={true} sein, dann die nötigen Attribute*/}
                      {/* Für ein Image muss imageHeader={true} sein, dann die nötigen Attribute*/}
                      <ProjectHeader
                          titel={t('product title')}
                          untertitel={t('product caption')}

                          videoHeader={true}
                          videoDesktop={mob_teaser_1920}
                          videoMobile={mob_teaser_640}
                          videoPoster={poster}
                          videoPosterMobile={poster}

                          hashtags={[
                              {t: "AR"}, {t: "HMI"}, {t: "interior"}, {t: "exterior"}, {t: "metaverse"},
                              {t: "multiplatform"}, {t: "movingplatform"}, {t: "hololens"}, {t: "digitaltwin"}, {t: "case"}
                          ]}
                          html={t('product description')}
                          text={undefined}
                      />
                  </Abschnitt>

                  {/* Trenner für Abstande oben/unten */}
                  <Trenner small={true}/>
                  {/* HR = Horizontal line, full width */}
                  <HR/>
                  <Trenner small={true}/>

                  <Abschnitt>
                      {/* ContentBlock1S (eine Spalte), mit HTML Textcontent auf der Translation */}
                      <ContentBlock1S
                          headline={t('content interior headline')}
                          video={mob_interior_1920}
                          videoMobile={mob_interior_640}
                          html={t('content interior html')}
                      />

                      <Trenner small={true}/>

                      {/* ContentBlock1S (eine Spalte), mit Plaintext Content aus der Translation */}
                      <ContentBlock1S
                          headline={t('content interfaces headline')}
                          video={mob_interface_1920}
                          videoMobile={mob_interface_640}
                          text={t('content interfaces text')}
                      />

                      <Trenner small={true} />
                      <ContentBlock1S
                          headline={t('content exterior headline')}
                          video={mob_exterior_1920}
                          videoMobile={mob_exterior_640}
                          html={t('content exterior html')}
                      />

                      <Trenner small={true} />
                      <ContentBlock1S
                          headline={t('content apps headline')}
                          video={mob_apps_1920}
                          videoMobile={mob_apps_640}
                          text={t('content apps text')}
                      />

                      <Trenner small={true} />
                      <ContentBlock1S
                          headline={t('content kommunikation headline')}
                          video={mob_communication_1920}
                          videoMobile={mob_communication_640}
                          html={t('content kommunikation html')}
                      />

                      <Trenner small={true} />
                      <ContentBlock1S
                          headline={t('content games headline')}
                          video={mob_games_1920}
                          videoMobile={mob_games_640}
                          text={t('content games text')}
                      />

                      <Trenner small={true} />
                      <ContentBlock1S
                          headline={t('content framework headline')}
                          text={t('content framework text')}
                      />
                  </Abschnitt>

                  <Trenner small={true}/>

                  <Abschnitt line={true} headline={t('content projektrahmen headline')}>
                      {/* ProjectFooter für weitere Infos zum Projekt. text und html werden als erstes angezeigt, dann alle children */}
                      {/* Mit <Reihe> und <SpalteLinks> (rechts) wird die ursprüngliche Aufteilung imitiert */}
                      <ProjectFooter text={undefined} html={undefined}>
                          <Reihe>
                              <SpalteLinks>
                                  <p className="headline">{t('footer projektmanagement headline')}</p>
                              </SpalteLinks>
                              <SpalteRechts>
                                  <p className="inhalt">{t('footer projektmanagement text')}</p>
                              </SpalteRechts>
                          </Reihe>

                          <Reihe>
                              <SpalteLinks>
                                  <p className="headline">{t('footer gestaltung headline')}</p>
                              </SpalteLinks>
                              <SpalteRechts>
                                  <p className="inhalt">{t('footer gestaltung text')}</p>
                              </SpalteRechts>
                          </Reihe>

                          <Reihe>
                              <SpalteLinks>
                                  <p className="headline">{t('footer programmierung headline')}</p>
                              </SpalteLinks>
                              <SpalteRechts>
                                  <p className="inhalt">{t('footer programmierung text')}</p>
                              </SpalteRechts>
                          </Reihe>

                          <Reihe>
                              <SpalteLinks>
                                  <p className="headline">{t('footer framework headline')}</p>
                              </SpalteLinks>
                              <SpalteRechts>
                                  <p className="inhalt">{t('footer framework text')}</p>
                              </SpalteRechts>
                          </Reihe>
                      </ProjectFooter>
                  </Abschnitt>

                  <FlyingContactClick />
              </Layout>
          </>
    );
};

export default AMobility

// Notwendig um Translation finden zu können, sonst keine Übersetzung möglich
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
